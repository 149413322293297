body {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

@-webkit-keyframes autofill {
  to {
    color: #21cf93;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.App {
  text-align: center;
}
